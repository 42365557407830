import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtesjrp.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3549805',
  schema: 'pbi_dte_sjrp',
  groupId: 'f1ec54f8-ab5f-415f-8d59-b9e4b142fcb1',
  analiseGeral: 'd4e4cf37-4fdf-4a19-a31a-dcce85eeabc3',
  analiseUsuarios: '3ba530d5-7119-4913-a9f0-570b65793ab6',
  analiseComunicados: '90666656-4be7-49dc-82c3-09bd383801b1',
  analiseProcurador: 'b6608b3a-2521-4835-8164-9b5c7e1cff6e',
  analiseEventos: 'c2597ce5-e67f-4bad-b266-66acb802f187',
  analiseContribuintes: 'aa7222b5-b05d-4292-91bd-c858ecece78b'
};
